import { SET_ALERT, REMOVE_ALERT, CLEAR_ALERTS } from "../actions/types";

const intitialState = [];

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = intitialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_ALERT:
      return [...state, payload];
    case CLEAR_ALERTS:
      return [];
    case REMOVE_ALERT:
      return state.filter((alert) => alert.id !== payload);
    default:
      return state;
  }
}
