import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga4";
import { createBrowserHistory } from "history";

const GoogleAnalytics = () => {
  const history = createBrowserHistory();
  useEffect(() => {
    // ReactGA.initialize("UA-124651082-1", {});
    ReactGA.initialize("G-47V8YJT5XX", {});
    ReactGA.send({ hitType: "pageview", path: window.location.pathname });
    // ReactGA.pageview(window.location.pathname);
  }, [history]);
  return null;
};

export default withRouter(GoogleAnalytics);
