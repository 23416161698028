import {
  GET_ACCOUNTS,
  GET_SINGLE_ACCOUNT,
  CREATE_ACCOUNT,
  UPDATE_ACCOUNT,
  ACCOUNT_LOADING,
  CLEAR_ACCOUNTS,
} from "../actions/types";

const initialState = {
  accounts: [],
  singleAccount: null,
  loading: true,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ACCOUNTS:
      return {
        ...state,
        accounts: payload,
        loading: false,
      };
    case GET_SINGLE_ACCOUNT:
    case CREATE_ACCOUNT:
    case UPDATE_ACCOUNT:
      return {
        ...state,
        singleAccount: payload,
        loading: false,
      };
    case ACCOUNT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_ACCOUNTS:
      return {
        accounts: [],
        singleAccount: null,
        loading: true,
      };
    default:
      return state;
  }
}
