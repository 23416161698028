import React from "react";
// import PropTypes from "prop-types";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer>
      <div className="pt-5 d-flex justify-content-center">
        <p className="mx-auto">&copy; {year} - Clarify Net Worth</p>
      </div>
    </footer>
  );
};

// Footer.propTypes = {};

export default Footer;
