import {
  GET_CASHFLOW,
  ADD_CASHFLOW,
  CLEAR_TOOLS,
  TOOLS_ERROR,
} from "../actions/types";

const initialState = {
  cashflow: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CASHFLOW:
    case ADD_CASHFLOW:
      return {
        ...state,
        cashflow: payload,
      };
    case TOOLS_ERROR:
      return {
        ...state,
      };
    case CLEAR_TOOLS:
      return {
        cashflow: null,
      };
    default:
      return state;
  }
}
