import axios from "axios";
import { setAlert, clearAlerts } from "./alert";
import { getToken } from "./plaid";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  RESET_PASSWORD,
  SEND_RESET_PASSWORD,
  LOGOUT,
  CLEAR_ACCOUNTS,
  CLEAR_AMOUNTS,
  CLEAR_GRAPHS,
  CLEAR_SINGLE_AMOUNT,
  CLEAR_PLAID,
  CLEAR_FEEDBACK,
  CLEAR_TOOLS,
} from "./types";
import setAuthToken from "../utils/setAuthToken";

//Load User
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get("/api/auth");

    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
    dispatch(getToken());
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

//Login User
export const login = (email, password) => async (dispatch) => {
  dispatch(clearAlerts());

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email, password });

  try {
    const res = await axios.post("/api/auth", body, config);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    dispatch(loadUser());
  } catch (err) {
    const error = err.response.data.errors;

    if (error) {
      error.forEach((error) => dispatch(setAlert(error.msg, "danger", 5000)));
    }
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

//Logout User
export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT });
  dispatch({ type: CLEAR_ACCOUNTS });
  dispatch({ type: CLEAR_AMOUNTS });
  dispatch({ type: CLEAR_SINGLE_AMOUNT });
  dispatch({ type: CLEAR_GRAPHS });
  dispatch({ type: CLEAR_PLAID });
  dispatch({ type: CLEAR_FEEDBACK });
  dispatch({ type: CLEAR_TOOLS });
};

//Register User
export const register =
  ({ email, password }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ email, password });

    try {
      const res = await axios.post("/api/users", body, config);

      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });

      dispatch(loadUser());
    } catch (err) {
      console.log(err);
      const error = err.response.data.errors;

      if (error) {
        error.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }
      dispatch({
        type: REGISTER_FAIL,
      });
    }
  };

//Send Reset Password
export const sendReset =
  ({ email }) =>
  async (dispatch) => {
    dispatch(logout());
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ email });

    try {
      const res = await axios.post("/api/auth/reset", body, config);

      dispatch({
        type: SEND_RESET_PASSWORD,
        payload: res.data,
      });
      dispatch(setAlert("Feedback sent, thanks!", "success", 5000));
    } catch (err) {
      const error = err.response.data.errors;
      if (error) {
        error.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }
      dispatch({
        type: REGISTER_FAIL,
      });
    }
  };
//Send Reset Password
export const updatePassword =
  ({ password, email, date }) =>
  async (dispatch) => {
    dispatch(logout());
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ password });
    const link = "/api/auth/reset/" + email + "/" + date;

    try {
      const res = await axios.post(link, body, config);

      dispatch({
        type: RESET_PASSWORD,
        payload: res.data,
      });

      dispatch(loadUser());
    } catch (err) {
      const error = err.response.data.errors;
      console.log(err.response.data);
      if (error) {
        error.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }
      dispatch({
        type: REGISTER_FAIL,
      });
    }
  };
