import React, { Fragment, useEffect, Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import "./App.css";

import Alert from "./components/layout/Alert";
import PrivateRoute from "./components/routing/PrivateRoute";
import PublicRoute from "./components/routing/PublicRoute";
import ScrollToTop from "./components/layout/ScrollToTop";
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/auth";
import setAuthToken from "./utils/setAuthToken";
import Spinner from "./components/layout/Spinner";
import Footer from "./components/layout/Footer";

import GoogleAnalytics from "./components/layout/GoogleAnalytics";

const Home = lazy(() => import("./components/public/Home"));
const NotFound = lazy(() => import("./components/public/NotFound"));
const Terms = lazy(() => import("./components/public/Terms"));
const Privacy = lazy(() => import("./components/public/Privacy"));
const Login = lazy(() => import("./components/auth/Login"));
const Register = lazy(() => import("./components/auth/Register"));
const Reset = lazy(() => import("./components/auth/Reset"));
const ResetPassword = lazy(() => import("./components/auth/ResetPassword"));

const Feedback = lazy(() => import("./components/settings/Feedback"));

const Dashboard = lazy(() => import("./components/dashboard/Dashboard"));
const Assets = lazy(() => import("./components/dashboard/Assets"));
const Compare = lazy(() => import("./components/dashboard/Compare"));

const AllAccounts = lazy(() => import("./components/accounts/AllAccounts"));
const AddAccounts = lazy(() => import("./components/accounts/AddAccounts"));
const SingleAccount = lazy(() => import("./components/accounts/SingleAccount"));
const EditAccounts = lazy(() => import("./components/accounts/EditAccounts"));
const DeleteAccount = lazy(() => import("./components/accounts/DeleteAccount"));

const Amounts = lazy(() => import("./components/amounts/Amounts"));
const AddToAll = lazy(() => import("./components/amounts/AddToAll"));
const EditAmount = lazy(() => import("./components/amounts/EditAmount"));
const DeleteAmount = lazy(() => import("./components/amounts/DeleteAmount"));

const Tools = lazy(() => import("./components/tools/Tools"));
const DebtSnowball = lazy(() => import("./components/tools/DebtSnowball"));
const CashFlowProjection = lazy(() =>
  import("./components/tools/CashFlowProjection")
);

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <div className="background">
      <Provider store={store}>
        <Router>
          <ScrollToTop>
            <Fragment>
              <Alert />
              <Suspense fallback={<Spinner />}>
                <Switch>
                  <PublicRoute
                    exact
                    path="/"
                    component={Home}
                    title="Clarify Net Worth - Gain Clarity and Grow Your Net Worth"
                  />
                  <PublicRoute
                    exact
                    path="/privacy"
                    component={Privacy}
                    title="Privacy Policy"
                  />
                  <PublicRoute
                    exact
                    path="/terms"
                    component={Terms}
                    title="Terms & Conditions"
                  />
                  <PublicRoute
                    exact
                    path="/login"
                    component={Login}
                    title="Log in"
                  />
                  <PublicRoute
                    exact
                    path="/register"
                    component={Register}
                    title="Register"
                  />
                  <PublicRoute
                    exact
                    path="/reset"
                    component={Reset}
                    title="Reset Password"
                  />
                  <PublicRoute
                    exact
                    path="/reset/:email/:date"
                    component={ResetPassword}
                    title="Reset Password"
                  />
                  <PrivateRoute
                    exact
                    path="/dashboard"
                    component={Dashboard}
                    title="Dashboard"
                  />
                  <PrivateRoute
                    exact
                    path="/dashboard/Compare"
                    component={Compare}
                    title="Compare Net Worth Timing"
                  />
                  <PrivateRoute
                    exact
                    path="/assets"
                    component={Assets}
                    title="Assets"
                  />
                  <PrivateRoute
                    exact
                    path="/debts"
                    component={Assets}
                    title="Debts"
                  />
                  <PrivateRoute
                    exact
                    path="/feedback"
                    component={Feedback}
                    title="Feedback"
                  />

                  <PrivateRoute
                    exact
                    path="/accounts"
                    component={AllAccounts}
                    title="All Accounts"
                  />
                  <PrivateRoute
                    exact
                    path="/accounts/add"
                    component={AddAccounts}
                    title="Add Account"
                  />
                  <PrivateRoute
                    exact
                    path="/accounts/:id/edit"
                    component={EditAccounts}
                    title="Edit Account"
                  />
                  <PrivateRoute
                    exact
                    path="/accounts/:id/delete"
                    component={DeleteAccount}
                    title="Delete Account"
                  />
                  <PrivateRoute
                    exact
                    path="/accounts/:id"
                    component={SingleAccount}
                  />

                  <PrivateRoute
                    exact
                    path="/amounts"
                    component={Amounts}
                    title="Amounts"
                  />
                  <PrivateRoute
                    exact
                    path="/amounts/add"
                    component={AddToAll}
                    title="Add to All"
                  />

                  <PrivateRoute
                    exact
                    path="/amounts/:id"
                    component={SingleAccount}
                  />
                  <PrivateRoute
                    exact
                    path="/amounts/:id/edit"
                    component={EditAmount}
                    title="Edit Amount"
                  />
                  <PrivateRoute
                    exact
                    path="/amounts/:id/delete"
                    component={DeleteAmount}
                    title="Delete Amount"
                  />
                  <PrivateRoute
                    exact
                    path="/tools"
                    component={Tools}
                    title="Tools"
                  />
                  <PrivateRoute
                    exact
                    path="/tools/debt-snowball"
                    component={DebtSnowball}
                    title="Debt Snowball"
                  />
                  <PrivateRoute
                    exact
                    path="/tools/cash-flow-projection"
                    component={CashFlowProjection}
                    title="Cash Flow Projection"
                  />
                  <PublicRoute component={NotFound} title="Page Not Found" />
                </Switch>
              </Suspense>
              <Footer />
            </Fragment>
          </ScrollToTop>
          <GoogleAnalytics />
        </Router>
      </Provider>
    </div>
  );
};

export default App;
