import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faDollarSign,
  faMoneyBill,
  faCreditCard,
  faBars,
  faCoins,
  faWallet,
  faSignOutAlt,
  faTools,
  faCommentDots,
} from "@fortawesome/free-solid-svg-icons";

import { logout } from "../../actions/auth";

const AuthNav = ({ logout }) => {
  const [lnav, setLnav] = useState(false);
  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    setActiveLink(window.location.pathname);
  }, []);

  return (
    <>
      <header className={lnav ? "header body-pd" : "header"} id="header">
        <div
          className="header-toggle"
          onClick={() => {
            setLnav(!lnav);
          }}
        >
          <FontAwesomeIcon icon={faBars} />
        </div>
      </header>
      <div className={lnav ? "l-navbar show-nav" : "l-navbar"} id="nav-bar">
        <nav className="nav">
          <div>
            <Link
              to="/dashboard"
              className="nav-logo nav-link"
              onClick={() => {
                setLnav(false);
              }}
            >
              <FontAwesomeIcon icon={faDollarSign} className="text-white" />
              <span className="nav-logo-name">ClarifyNetWorth</span>
            </Link>
            <div className="nav-list">
              <Link
                to="/dashboard"
                className={
                  activeLink === "/dashboard" ? "nav-link active" : "nav-link"
                }
                onClick={() => {
                  setLnav(false);
                }}
              >
                <FontAwesomeIcon icon={faHome} className="text-white" />
                <span className="nav-name">Dashboard</span>
              </Link>
              <Link
                to="/assets"
                className={
                  activeLink === "/assets" ? "nav-link active" : "nav-link"
                }
                onClick={() => {
                  setLnav(false);
                }}
              >
                <FontAwesomeIcon icon={faMoneyBill} className="text-white" />
                <span className="nav-name">Assets</span>
              </Link>
              <Link
                to="/debts"
                className={
                  activeLink === "/debts" ? "nav-link active" : "nav-link"
                }
                onClick={() => {
                  setLnav(false);
                }}
              >
                <FontAwesomeIcon icon={faCreditCard} className="text-white" />
                <span className="nav-name">Debts</span>
              </Link>
              <Link
                to="/amounts"
                className={
                  activeLink === "/amounts" ? "nav-link active" : "nav-link"
                }
                onClick={() => {
                  setLnav(false);
                }}
              >
                <FontAwesomeIcon icon={faCoins} className="text-white" />
                <span className="nav-name">Amounts</span>
              </Link>
              <Link
                to="/accounts"
                className={
                  activeLink === "/accounts" ? "nav-link active" : "nav-link"
                }
                onClick={() => {
                  setLnav(false);
                }}
              >
                <FontAwesomeIcon icon={faWallet} className="text-white" />
                <span className="nav-name">Accounts</span>
              </Link>
              <Link
                to="/tools"
                className={
                  activeLink === "/tools" ? "nav-link active" : "nav-link"
                }
                onClick={() => {
                  setLnav(false);
                }}
              >
                <FontAwesomeIcon icon={faTools} className="text-white" />
                <span className="nav-name">Tools</span>
              </Link>
            </div>
          </div>
          <div>
            <Link
              to="/feedback"
              className={
                activeLink === "/tools" ? "nav-link active" : "nav-link"
              }
              onClick={() => {
                setLnav(false);
              }}
            >
              <FontAwesomeIcon icon={faCommentDots} className="text-white" />
              <span className="nav-name">Feedback</span>
            </Link>
            <Link
              to="/"
              className="nav-link"
              onClick={() => {
                logout();
              }}
            >
              <FontAwesomeIcon icon={faSignOutAlt} className="text-white" />
              <span className="nav-name">SignOut</span>
            </Link>
          </div>
        </nav>
      </div>
    </>
  );
};

AuthNav.propTypes = {
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { logout })(AuthNav);
