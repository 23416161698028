import {
  SEND_FEEDBACK,
  SEND_THUMBS,
  GET_THUMBS,
  REMOVE_FEEDBACK,
  CLEAR_FEEDBACK,
  FEEDBACK_ERROR,
} from "../actions/types";

const initialState = {
  thumbs: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_THUMBS:
    case SEND_THUMBS:
      return {
        ...state,
        thumbs: payload,
      };
    case SEND_FEEDBACK:
    case REMOVE_FEEDBACK:
      return {
        ...state,
      };

    case CLEAR_FEEDBACK:
      return {
        ...state,
        thumbs: null,
      };

    case FEEDBACK_ERROR:
    default:
      return state;
  }
}
