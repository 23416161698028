import axios from "axios";
import { setAlert } from "./alert";
import {
  GET_PLAID,
  SET_PLAID,
  GET_PLAID_TRANSACTIONS,
  GET_PLAID_AMOUNTS,
  PLAID_ERROR,
  GET_PLAID_ACCOUNTS,
  CLEAR_PLAID_AMOUNTS,
  CLEAR_PLAID,
} from "./types";

export const getToken = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({});

  try {
    // const res = await axios.post("/api/plaid/create-link-token", body, config);
    // dispatch({
    //   type: GET_PLAID,
    //   payload: res.data,
    // });
  } catch (err) {
    console.log(err);
    const error = err.response.data.errors;

    if (error) {
      error.forEach((error) => dispatch(setAlert(error.msg, "danger", 5000)));
    }
    dispatch({
      type: PLAID_ERROR,
    });
  }
};

export const exchangePublicToken = (token) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ token });

  try {
    // const res = await axios.post(
    //   "/api/plaid/exchange-public-token",
    //   body,
    //   config
    // );
    // dispatch({
    //   type: SET_PLAID,
    //   payload: res.data,
    // });
  } catch (err) {
    console.log(err);
    const error = err.response.data.errors;

    if (error) {
      error.forEach((error) => dispatch(setAlert(error.msg, "danger", 5000)));
    }
    dispatch({
      type: PLAID_ERROR,
    });
  }
};

export const getItems = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/plaid/item");
    dispatch({
      type: GET_PLAID_TRANSACTIONS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    const error = err.response.data.errors;

    if (error) {
      error.forEach((error) => dispatch(setAlert(error.msg, "danger", 5000)));
    }
    dispatch({
      type: PLAID_ERROR,
    });
  }
};

export const getAccounts = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/plaid/accounts");
    dispatch({
      type: GET_PLAID_ACCOUNTS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch(setAlert(err, "danger", 5000));
    // const error = err;
    // .response.data.errors;

    // if (error) {
    //   error.forEach((error) => dispatch(setAlert(error.msg, "danger", 5000)));
    // }
    dispatch({
      type: PLAID_ERROR,
    });
  }
};

//Get all accounts by logged in user
export const getPlaidBalance = (accountId) => async (dispatch) => {
  dispatch(clearPlaidAmounts());
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ accountId });

  try {
    // const res = await axios.post(
    //   "/api/plaid/get-account-balance",
    //   body,
    //   config
    // );
    // dispatch({
    //   type: GET_PLAID_AMOUNTS,
    //   payload: res.data,
    // });
  } catch (err) {
    console.log(err);
    const error = err.response.data.errors;

    if (error) {
      error.forEach((error) => dispatch(setAlert(error.msg, "danger", 5000)));
    }
    dispatch({
      type: PLAID_ERROR,
    });
  }
};

//Get all accounts by logged in user
export const getTransactions = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({});

  try {
    const res = await axios.post("/api/plaid/get-transactions", body, config);

    dispatch({
      type: GET_PLAID_TRANSACTIONS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    const error = err.response.data.errors;

    if (error) {
      error.forEach((error) => dispatch(setAlert(error.msg, "danger", 5000)));
    }
    dispatch({
      type: PLAID_ERROR,
    });
  }
};

export const clearPlaid = () => (dispatch) => {
  dispatch({
    type: CLEAR_PLAID,
  });
};
export const clearPlaidAmounts = () => (dispatch) => {
  dispatch({
    type: CLEAR_PLAID_AMOUNTS,
  });
};
