export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const CLEAR_ALERTS = "CLEAR_ALERTS";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const SEND_RESET_PASSWORD = "SEND_RESET_PASSWORD";
export const LOGOUT = "LOGOUT";

export const SEND_FEEDBACK = "SEND_FEEDBACK";
export const SEND_THUMBS = "SEND_THUMBS";
export const GET_THUMBS = "GET_THUMBS";
export const REMOVE_FEEDBACK = "REMOVE_FEEDBACK";
export const FEEDBACK_ERROR = "FEEDBACK_ERROR";
export const CLEAR_FEEDBACK = "CLEAR_FEEDBACK";

export const GET_ACCOUNTS = "GET_ACCOUNTS";
export const GET_SINGLE_ACCOUNT = "GET_SINGLE_ACCOUNT";
export const CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const ACCOUNTS_ERROR = "ACCOUNTS_ERROR";
export const ACCOUNT_LOADING = "ACCOUNT_LOADING";
export const CLEAR_ACCOUNTS = "CLEAR_ACCOUNTS";

export const GET_AMOUNTS = "GET_AMOUNTS";
export const GET_LATEST_AMOUNTS = "GET_LATEST_AMOUNTS";
export const GET_PREV_MONTH_AMOUNTS = "GET_PREV_MONTH_AMOUNTS";
export const GET_PREV_YEAR_AMOUNTS = "GET_PREV_YEAR_AMOUNTS";
export const GET_SINGLE_AMOUNT = "GET_SINGLE_AMOUNT";
export const ADD_AMOUNT = "ADD_AMOUNT";
export const ADD_MULTIPLE_AMOUNTS = "ADD_MULTIPLE_AMOUNTS";
export const UPDATE_AMOUNT = "UPDATE_AMOUNT";
export const DELETE_AMOUNT = "DELETE_AMOUNT";
export const AMOUNTS_ERROR = "AMOUNTS_ERROR";
export const AMOUNTS_LOADING = "AMOUNTS_LOADING";
export const CLEAR_SINGLE_AMOUNT = "CLEAR_SINGLE_AMOUNT";
export const CLEAR_AMOUNTS = "CLEAR_AMOUNTS";

export const GET_DATA = "GET_DATA";
export const GET_SINGLE_DATA = "GET_SINGLE_DATA";
export const DATA_LOADING = "DATA_LOADING";
export const GRAPH_ERROR = "GRAPH_ERROR";
export const CLEAR_GRAPHS = "CLEAR_GRAPHS";

export const GET_PLAID = "GET_PLAID";
export const SET_PLAID = "SET_PLAID";
export const GET_PLAID_ACCOUNTS = "GET_PLAID_ACCOUNTS";
export const GET_PLAID_AMOUNTS = "GET_PLAID_AMOUNTS";
export const GET_PLAID_TRANSACTIONS = "GET_PLAID_TRANSACTIONS";
export const PLAID_ERROR = "PLAID_ERROR";
export const CLEAR_PLAID = "CLEAR_PLAID";
export const CLEAR_PLAID_AMOUNTS = "CLEAR_PLAID_AMOUNTS";

export const GET_CASHFLOW = "GET_CASHFLOW";
export const ADD_CASHFLOW = "ADD_CASHFLOW";
export const CLEAR_TOOLS = "CLEAR_TOOLS";
export const TOOLS_ERROR = "TOOLS_ERROR";
