import { combineReducers } from "redux";
import alert from "./alert";
import auth from "./auth";
import accounts from "./accounts";
import amounts from "./amounts";
import feedback from "./feedback";
import graphs from "./graphs";
import plaid from "./plaid";
import tools from "./tools";

export default combineReducers({
  accounts,
  alert,
  amounts,
  auth,
  feedback,
  graphs,
  tools,
  plaid,
});
