import React from "react";
import { Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import Navbar from "../layout/Navbar";

const PrivateRoute = ({ component: Component, title, ...rest }) => (
  <>
    <Helmet>
      <title>
        {title ? title + " - Clarify Net Worth" : "Clarify Net Worth"}
      </title>
    </Helmet>
    <Navbar />
    <div className="body">
      <Route {...rest} render={(props) => <Component {...props} />} />
    </div>
  </>
);

export default PrivateRoute;
