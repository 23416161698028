import {
  GET_PLAID,
  GET_PLAID_TRANSACTIONS,
  GET_PLAID_ACCOUNTS,
  GET_PLAID_AMOUNTS,
  PLAID_ERROR,
  CLEAR_PLAID_AMOUNTS,
  CLEAR_PLAID,
} from "../actions/types";

const initialState = {
  token: null,
  transactions: [],
  accounts: [],
  amounts: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PLAID:
      return {
        ...state,
        token: payload,
      };
    case GET_PLAID_TRANSACTIONS:
      return {
        ...state,
        transactions: payload,
      };
    case GET_PLAID_ACCOUNTS:
      return {
        ...state,
        accounts: payload,
      };
    case GET_PLAID_AMOUNTS:
      return {
        ...state,
        amounts: payload,
      };
    case CLEAR_PLAID_AMOUNTS:
      return {
        ...state,
        amounts: [],
      };
    case PLAID_ERROR:
      return {
        ...state,
      };
    case CLEAR_PLAID:
      return {
        token: null,
        transactions: [],
        accounts: [],
        amounts: [],
      };
    default:
      return state;
  }
}
