import {
  GET_AMOUNTS,
  GET_LATEST_AMOUNTS,
  GET_PREV_MONTH_AMOUNTS,
  GET_PREV_YEAR_AMOUNTS,
  GET_SINGLE_AMOUNT,
  CLEAR_SINGLE_AMOUNT,
  CLEAR_AMOUNTS,
  ADD_AMOUNT,
  ADD_MULTIPLE_AMOUNTS,
  UPDATE_AMOUNT,
  AMOUNTS_ERROR,
  AMOUNTS_LOADING,
} from "../actions/types";

const initialState = {
  amounts: [],
  currentAmounts: [],
  prevMonthAmounts: [],
  prevYearAmounts: [],
  singleAmount: null,
  loading: true,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_AMOUNTS:
      return {
        ...state,
        amounts: payload,
        loading: false,
      };
    case GET_LATEST_AMOUNTS:
    case ADD_MULTIPLE_AMOUNTS:
      return {
        ...state,
        currentAmounts: payload,
        loading: false,
      };
    case GET_PREV_MONTH_AMOUNTS:
      return {
        ...state,
        prevMonthAmounts: payload,
        loading: false,
      };
    case GET_PREV_YEAR_AMOUNTS:
      return {
        ...state,
        prevYearAmounts: payload,
        loading: false,
      };
    case GET_SINGLE_AMOUNT:
    case ADD_AMOUNT:
    case UPDATE_AMOUNT:
      return {
        ...state,
        singleAmount: payload,
        loading: false,
      };
    case AMOUNTS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_SINGLE_AMOUNT:
      return {
        ...state,
        singleAmount: null,
      };
    case CLEAR_AMOUNTS:
      return {
        ...state,
        amounts: [],
        currentAmounts: [],
        prevMonthAmounts: [],
        prevYearAmounts: [],
      };
    case AMOUNTS_ERROR:
    default:
      return state;
  }
}
