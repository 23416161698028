import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <Fragment>
      <header className=" header d-flex justify-content-between" id="header">
        <div className="header-toggle nav-logo pt-4">
          <Link className="nav-logo-link" to="/">
            ClarifyNetWorth
          </Link>
        </div>
        <div className="me-2 d-flex justify-content-between">
          <Link to="/login" className="px-2 pt-4 nav-link-bar">
            Login
          </Link>
          <Link to="/register" className="px-2 pt-4 nav-link-bar">
            Register
          </Link>
        </div>
      </header>
    </Fragment>
  );
};

export default Navbar;
