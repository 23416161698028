import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import AuthNav from "../layout/AuthNav";

const PrivateRoute = ({
  component: Component,
  auth: { isAuthenticated, loading },
  title,
  ...rest
}) => (
  <>
    <Helmet>
      <title>
        {title ? title + " - Clarify Net Worth" : "Clarify Net Worth"}
      </title>
    </Helmet>
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated && !loading ? (
          <Redirect to="/login" />
        ) : (
          <>
            <AuthNav />
            <div className="body-nav min-vh-100">
              <h1 className="display-4">{title}</h1>
              <Component {...props} />
            </div>
          </>
        )
      }
    />
  </>
);

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
